@import "../../assets/styles/utils";

.index-banner{
    background-color: $color-main;
    box-sizing: border-box;
    @include res(height,100vh,(md:500px,sm:400px,xs:260px));
    @include res(padding-top,$header-height-base,$header-height-ratio);
    @include res(padding-right,176px, 15 / 176);
    .banner-cont{
        height: 100%;
        position: relative;
        .swiper-container{
            height: 100%;
            overflow: hidden;
            .swiper-slide{
                overflow: hidden;
                &.swiper-slide-active,&.swiper-slide-duplicate-active{
                    .slide-inner{
                        b{
                            transform: scale(1);
                        }
                    }
                }
                .slide-inner{
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    b{
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: scale(1.2);
                        transition: all .8s;
                        // transition-delay: .2s;
                    }
                    .video{
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        video{
                            // @include res(width,100%);
                            // @include res(height,auto);
                            width: auto;
                            @include res(height,100%);
                        }
                    }
                    // &::after{
                    //     content: '';
                    //     display: block;
                    //     width: 100%;
                    //     height: 100%;
                    //     background-color: rgba(0,0,0,.2);
                    //     position: absolute;
                    //     top: 0;
                    //     left: 0;
                    // }
                }
            }
        }
        .swiper-pagination{
            width: 11px;
            height: 100%;
            top:0;
            bottom: auto;
            left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            transform: none;
            @include res(padding-top,190px, 50 / 190);
            @include res(right,-90px,-10 / -90);
            .swiper-pagination-bullet{
                width: 10px;
                height: 10px;
                opacity: 1;
                background: transparent;
                border: 2px solid #fff;
                position: relative;
                box-sizing: border-box;
                transition: all .3s;
                &:not(:last-child){
                    @include res(margin-bottom,20px, 5 / 20);
                }
                &.swiper-pagination-bullet-active{
                    background-color: #fff;
                }
            }
        }
        .index-banner-text{
            height: 0;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;
            .text-item{
                position: absolute;
                top: 0;
                left: 0;
                transform: translateY(-50%);
                display: block;
                @include res(padding-left,176px, 15 / 176);
                @include res(padding-right,30px);
                .title1{
                    color: #fff;
                    font-weight: bold;
                    line-height: 1;
                    @include res(max-width,700px);
                    @include res(font-size,80px, 26 / 80);
                    @include res(margin-bottom,30px, 15 / 30);
                }
                .title2{
                    color: #fff;
                    @include res(font-size,32px, 16 / 32);
                }
            }
        }
    }
}

.index-product{
    background-color: #f1f4f7;
    position: relative;
    &::after{
        content: '';
        display: block;
        height: 100%;
        background-color: $color-main;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        @include res(width,20%, (sm:100%));
    }
    .cont{
        position: relative;
        z-index: 1;
        &::after{
            content: '';
            display: block;
            height: 100%;
            background-color: $color-main;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            @include res(width,33.33%,(md:80%,sm:100%));
        }
        .product-top{
            align-items: flex-end;
            justify-content: space-between;
            position: relative;
            z-index: 2;
            @include res(display,flex,(md:block));
            @include res(padding-top,90px, 30 / 90);
            // @include res(margin-bottom,10px, 10 / 10);
            .product-title{
                color: #fff;
                font-weight: bold;
                @include res(font-size,60px, 30 / 60);
                @include res(margin-bottom,0,(md:20px));
            }
            .product-tabs{
                display: flex;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                overflow-x: auto;
                @include res(margin-right,25px,(md:0));
                @include res(color,#000,(md:#fff));
                @include res(font-size,20px, 16 / 20);
                @include res(margin-top,-20px, -15 / -20);
                .tab-item{
                    position: relative;
                    cursor: pointer;
                    transition: all .25s;
                    @include res(padding-top,20px, 15 / 20);
                    @include res(padding-bottom,20px, 15 / 20);
                    &:not(:first-child){
                        @include res(margin-left,30px, 10 / 30);
                    }
                    &:not(.active){
                        &:hover{
                            @include res(color,$color-main,(md:$color-main-yellow));
                        }
                    }
                    &::after{
                        content: '';
                        display: block;
                        width: 0;
                        background-color: $color-main-yellow;
                        position: absolute;
                        bottom: 10px;
                        left: 0;
                        z-index: 0;
                        transition: all .3s;
                        @include res(height,3px,2 / 3);
                    }
                    &.active{
                        font-weight: bold;
                        @include res(color,$color-main,(md:$color-main-yellow));
                        &::after{
                            width: 100%;
                        }
                    }
                }
            }
        }
        .product-swiper{
            position: relative;
            z-index: 2;
            margin-left: -20px;
            margin-right: -20px;
            @include res(padding-bottom,0,(sm:1px));
            .more-cont{
                z-index: 2;
                top: calc(50% + 10px);
                align-items: center;
                justify-content: center;
                @include res(margin,0,(sm:0 auto));
                @include res(position,absolute,(sm:static));
                @include res(display,block,(sm:flex));
                @include res(right,-20px);
                @include res(margin-top,0,(sm:20px,xs:10px));
                @include res(margin-bottom,0,(sm:20px,xs:10px));
                .more{
                    display: none;
                    top: 50%;
                    left: 50%;
                    border-radius: 50%;
                    color: #fff;
                    overflow: hidden;
                    @include res(transform,translate3d(-50%,-50%,0),(sm:translate3d(0,0,0)));
                    @include res(background-color,$color-main,(sm:$color-main-yellow));
                    @include res(position,absolute,(sm:static));
                    &:hover{
                        .iconfont{
                            background-color: $color-main-yellow;
                        }
                    }
                    .iconfont{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all .3s;
                        @include res(width,50px, 40 / 50);
                        @include res(height,50px, 40 / 50);
                        @include res(font-size,30px, 20 / 30);
                    }
                }
            }
            .swiper{
                padding: 20px;
                @include res(padding-bottom,0);
            }
        }
    }
}

.index-search{
    position: relative;
    &::after{
        content: '';
        display: block;
        height: 100%;
        background-color: $color-main;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        @include res(width,20%);
    }
    .container{
        position: relative;
        z-index: 1;
        .cont{
            position: relative;
            @include res(padding-top,90px, 20 / 90);
            @include res(padding-bottom,110px, 20 / 110);
            @include res(padding-left,95px, 0 / 95);
            .bg{
                background-color: #000;
                position: absolute;
                top: 0;
                height: 100%;
                overflow: hidden;
                @include res(left,0,(xs:-15px));
                @include res(width,120%);
                b{
                    display: block;
                    width: 100%;
                    height: 150%;
                    opacity: .5;
                    background-position: bottom left;
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: absolute;
                    top: -50%;
                    left: 0;
                }
            }
            .search{
                position: relative;
                z-index: 1;
                color: #fff;
                align-items: center;
                justify-content: space-between;
                @include res(display,flex,(md:block));
                .search-title{
                    flex-shrink: 0;
                    line-height: 1;
                    @include res(margin-right,80px, 0 / 80);
                    @include res(margin-bottom,0,(md:20px,xs:10px));
                    h1{
                        font-weight: bold;
                        @include res(font-size,50px, 22 / 50);
                    }
                    h2{
                        font-weight: bold;
                        @include res(font-size,64px, 30 / 64);
                    }
                }
                .search-form{
                    flex-grow: 1;
                    .form{
                        align-items: center;
                        justify-content: space-between;
                        @include res(display,flex,(xs:block));
                        .form-radio-group{
                            flex-shrink: 0;
                            @include res(width,76.3%,(xs:100%));
                            .form-title{
                                @include res(font-size,22px,16 / 22);
                                @include res(margin-bottom,20px, 10 / 20);
                                @include res(margin-top,20px, 10 / 20);
                            }
                            .form-radio{
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                white-space: nowrap;
                                flex-wrap: wrap;
                                label{
                                    display: inline-block;
                                    position: relative;
                                    cursor: pointer;
                                    flex-shrink: 0;
                                    width: fit-content;
                                    @include res(margin-right,40px, 20 / 40);
                                    @include res(margin-bottom,20px, 10 / 20);
                                    input{
                                        opacity: 0;
                                        @include res(margin-right,15px,(sm:10px));
                                    }
                                    span{
                                        &::before{
                                            content: '';
                                            display: block;
                                            border-radius: 50%;
                                            border: 1px solid #fff;
                                            position: absolute;
                                            left: 0;
                                            @include res(width,20px,(sm:16px));
                                            @include res(height,20px,(sm:16px));
                                            @include res(top,calc(50% - 10px),(sm:calc(50% - 8px)));
                                        }
                                        &::after{
                                            content: '';
                                            display: block;
                                            border-radius: 50%;
                                            background-color: #fff;
                                            position: absolute;
                                            left: 4px;
                                            opacity: 0;
                                            transition: all .25s;
                                            @include res(width,12px,(sm:8px));
                                            @include res(height,12px,(sm:8px));
                                            @include res(top,calc(50% - 6px),(sm:calc(50% - 4px)));
                                        }
                                    }
                                    input:checked + span{
                                        &::after{
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                        .form-btn{
                            flex-shrink: 0;
                            @include res(width,22.36%,(xs:100%));
                            @include res(margin-top,0,(xs:10px));
                            .search-btn{
                                width: 100%;
                                display: block;
                                background-color: transparent;
                                cursor: pointer;
                                border: none;
                                outline: none;
                                transition: all .3s;
                                border: solid #fff;
                                text-align: center;
                                color: #fff;
                                @include res(font-size,20px, 16 / 20);
                                @include res(padding-top,20px, 10 / 20);
                                @include res(padding-bottom,20px, 10 / 20);
                                @include res(border-width,3px, 2 / 3);
                                &:hover{
                                    background-color: $color-main;
                                    border: solid $color-main;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.index-about{
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    @include res(display,flex,(xs:block));
    @include res(padding-top,130px, 30 / 130);
    @include res(padding-bottom,140px, 0 / 140);
    &::after{
        content: '';
        width: 100%;
        display: block;
        background-color: #f1f4f7;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        @include res(height,270px, 80 / 270);
    }
    .left-text{
        flex-shrink: 0;
        @include res(width,50%,(xs:100%));
        @include res(padding-left,176px, 15 / 176);
        @include res(padding-right,170px, 15 / 170);
        @include res(margin-bottom,0,(xs:20px));
        .title1{
            color: #000;
            font-weight: bold;
            @include res(margin-bottom,130px, 40 / 130);
            @include res(font-size,60px, 30 / 60);
        }
        .title2{
            color: $color-main;
            max-width: 430px;
            position: relative;
            font-style: italic;
            @include res(margin-bottom,60px, 20 / 60);
            @include res(font-size,50px, 22 / 50);
            span{
                position: absolute;
                top: 50%;
                font-style: normal;
                @include res(right,-14px,(xs:140px));
                @include res(font-size,120px, 40 / 120);
            }
        }
        .text{
            @include res(text-align,justify,(xs:left));
            text-justify: inter-ideograph;/*IE*/
            @include res(line-height,2, 1.5 / 2);
            @include res(font-size,18px, 14 / 18);
        }
        .more{
            @include res(margin-top,45px, 20 / 45);
            a{
                display: inline-block;
                color: $color-main;
                position: relative;
                transition: all .3s;
                border: solid $color-main;
                @include res(font-size,18px, 16 / 18);
                @include res(padding-top,20px, 10 / 20);
                @include res(padding-bottom,20px, 10 / 20);
                @include res(padding-left,70px, 30 / 70);
                @include res(padding-right,70px, 30 / 70);
                @include res(border-width,3px, 2 / 3);
                &:hover{
                    background-color: $color-main;
                    color: #fff;
                }
            }
        }
    }
    .right-img{
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
        @include res(width,50%,(xs:100%));
        b{
            display: block;
            width: 100%;
            height: 120%;
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 0;
            left:0;
            z-index: 0;
        }
        .img-text{
            position: relative;
            z-index: 1;
            background: rgba(0,0,0,.1);
            @include res(padding-top,45px, 20 / 45);
            @include res(padding-bottom,45px, 20 / 45);
            @include res(padding-left,170px, 10 / 170);
            @include res(padding-right,10px, 10 / 10);
            .item{
                color: #fff;
                @include res(text-align,null,(xs:center));
                &:not(:last-child){
                    @include res(margin-bottom,50px, 20 / 50);
                }
                h1{
                    @include res(font-size,80px, 40 / 80);
                }
                p{
                    opacity: .8;
                    @include res(font-size,18px, 16 / 18);
                }
            }
        }
    }
}

@media screen and (max-width: 1680px) {
    .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label{
        @include res(width,25%,(mmd:25%,md:33.33%,xs:50%));
    }
}

@media screen and (max-width: 992px) {
    .index-banner{
        padding-right: 70px;
    }
    .index-banner .banner-cont .swiper-pagination{
        right: -40px;
    }
}
@media screen and (max-width: 767px) {
    .index-banner{
        padding-right: 0px;
    }
    .index-banner .banner-cont .swiper-pagination{
        right: 10px;
    }
}
