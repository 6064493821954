.index-banner {
  background-color: #007f86;
  box-sizing: border-box;
  height: 100vh;
  padding-top: 120px;
  padding-right: 176px;
}

@media (max-width: 1024px) {
  .index-banner {
    height: 500px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: 260px;
  }
}

@media (max-width: 1600px) {
  .index-banner {
    padding-top: 108px;
  }
}

@media (max-width: 1366px) {
  .index-banner {
    padding-top: 96px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    padding-top: 84px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    padding-top: 72px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    padding-top: 60px;
  }
}

@media (max-width: 1600px) {
  .index-banner {
    padding-right: 143.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner {
    padding-right: 111.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    padding-right: 79.4px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    padding-right: 47.2px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    padding-right: 15px;
  }
}

.index-banner .banner-cont {
  height: 100%;
  position: relative;
}

.index-banner .banner-cont .swiper-container {
  height: 100%;
  overflow: hidden;
}

.index-banner .banner-cont .swiper-container .swiper-slide {
  overflow: hidden;
}

.index-banner .banner-cont .swiper-container .swiper-slide.swiper-slide-active .slide-inner b, .index-banner .banner-cont .swiper-container .swiper-slide.swiper-slide-duplicate-active .slide-inner b {
  transform: scale(1);
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.2);
  transition: all .8s;
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner .video {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner .video video {
  width: auto;
  height: 100%;
}

.index-banner .banner-cont .swiper-pagination {
  width: 11px;
  height: 100%;
  top: 0;
  bottom: auto;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: none;
  padding-top: 190px;
  right: -90px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .swiper-pagination {
    padding-top: 162px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .swiper-pagination {
    padding-top: 134px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .swiper-pagination {
    padding-top: 106px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination {
    padding-top: 78px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination {
    padding-top: 50px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .swiper-pagination {
    right: -74px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .swiper-pagination {
    right: -58px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .swiper-pagination {
    right: -42px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination {
    right: -26px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination {
    right: -10px;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 1;
  background: transparent;
  border: 2px solid #fff;
  position: relative;
  box-sizing: border-box;
  transition: all .3s;
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 17px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 14px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 11px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 8px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 5px;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}

.index-banner .banner-cont .index-banner-text {
  height: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
}

.index-banner .banner-cont .index-banner-text .text-item {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  display: block;
  padding-left: 176px;
  padding-right: 30px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 143.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 111.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 79.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 47.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 15px;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title1 {
  color: #fff;
  font-weight: bold;
  line-height: 1;
  max-width: 700px;
  font-size: 80px;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 69.2px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 58.4px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 47.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 36.8px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 26px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 15px;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title2 {
  color: #fff;
  font-size: 32px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 28.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 25.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 19.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 16px;
  }
}

.index-product {
  background-color: #f1f4f7;
  position: relative;
}

.index-product::after {
  content: '';
  display: block;
  height: 100%;
  background-color: #007f86;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 20%;
}

@media (max-width: 991px) {
  .index-product::after {
    width: 100%;
  }
}

.index-product .cont {
  position: relative;
  z-index: 1;
}

.index-product .cont::after {
  content: '';
  display: block;
  height: 100%;
  background-color: #007f86;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 33.33%;
}

@media (max-width: 1024px) {
  .index-product .cont::after {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .index-product .cont::after {
    width: 100%;
  }
}

.index-product .cont .product-top {
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  display: flex;
  padding-top: 90px;
}

@media (max-width: 1024px) {
  .index-product .cont .product-top {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index-product .cont .product-top {
    padding-top: 78px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-top {
    padding-top: 66px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top {
    padding-top: 54px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-top {
    padding-top: 42px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-top {
    padding-top: 30px;
  }
}

.index-product .cont .product-top .product-title {
  color: #fff;
  font-weight: bold;
  font-size: 60px;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .index-product .cont .product-top .product-title {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-top .product-title {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-title {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-top .product-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-top .product-title {
    font-size: 30px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-title {
    margin-bottom: 20px;
  }
}

.index-product .cont .product-top .product-tabs {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  margin-right: 25px;
  color: #000;
  font-size: 20px;
  margin-top: -20px;
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs {
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs {
    color: #fff;
  }
}

@media (max-width: 1600px) {
  .index-product .cont .product-top .product-tabs {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-top .product-tabs {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-top .product-tabs {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-top .product-tabs {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-product .cont .product-top .product-tabs {
    margin-top: -19px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-top .product-tabs {
    margin-top: -18px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs {
    margin-top: -17px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-top .product-tabs {
    margin-top: -16px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-top .product-tabs {
    margin-top: -15px;
  }
}

.index-product .cont .product-top .product-tabs .tab-item {
  position: relative;
  cursor: pointer;
  transition: all .25s;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-top: 19px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-top: 18px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-top: 17px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-top: 16px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-bottom: 19px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-bottom: 18px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-bottom: 17px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-top .product-tabs .tab-item {
    padding-bottom: 15px;
  }
}

.index-product .cont .product-top .product-tabs .tab-item:not(:first-child) {
  margin-left: 30px;
}

@media (max-width: 1600px) {
  .index-product .cont .product-top .product-tabs .tab-item:not(:first-child) {
    margin-left: 26px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-top .product-tabs .tab-item:not(:first-child) {
    margin-left: 22px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs .tab-item:not(:first-child) {
    margin-left: 18px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-top .product-tabs .tab-item:not(:first-child) {
    margin-left: 14px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-top .product-tabs .tab-item:not(:first-child) {
    margin-left: 10px;
  }
}

.index-product .cont .product-top .product-tabs .tab-item:not(.active):hover {
  color: #007f86;
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs .tab-item:not(.active):hover {
    color: #fabe00;
  }
}

.index-product .cont .product-top .product-tabs .tab-item::after {
  content: '';
  display: block;
  width: 0;
  background-color: #fabe00;
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 0;
  transition: all .3s;
  height: 3px;
}

@media (max-width: 1600px) {
  .index-product .cont .product-top .product-tabs .tab-item::after {
    height: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-top .product-tabs .tab-item::after {
    height: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs .tab-item::after {
    height: 2.4px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-top .product-tabs .tab-item::after {
    height: 2.2px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-top .product-tabs .tab-item::after {
    height: 2px;
  }
}

.index-product .cont .product-top .product-tabs .tab-item.active {
  font-weight: bold;
  color: #007f86;
}

@media (max-width: 1024px) {
  .index-product .cont .product-top .product-tabs .tab-item.active {
    color: #fabe00;
  }
}

.index-product .cont .product-top .product-tabs .tab-item.active::after {
  width: 100%;
}

.index-product .cont .product-swiper {
  position: relative;
  z-index: 2;
  margin-left: -20px;
  margin-right: -20px;
  padding-bottom: 0;
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper {
    padding-bottom: 1px;
  }
}

.index-product .cont .product-swiper .more-cont {
  z-index: 2;
  top: calc(50% + 10px);
  align-items: center;
  justify-content: center;
  margin: 0;
  position: absolute;
  display: block;
  right: -20px;
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont {
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont {
    position: static;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont {
    display: flex;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-swiper .more-cont {
    margin-top: 10px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-swiper .more-cont {
    margin-bottom: 10px;
  }
}

.index-product .cont .product-swiper .more-cont .more {
  display: none;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  color: #fff;
  overflow: hidden;
  transform: translate3d(-50%, -50%, 0);
  background-color: #007f86;
  position: absolute;
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont .more {
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont .more {
    background-color: #fabe00;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont .more {
    position: static;
  }
}

.index-product .cont .product-swiper .more-cont .more:hover .iconfont {
  background-color: #fabe00;
}

.index-product .cont .product-swiper .more-cont .more .iconfont {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  width: 50px;
  height: 50px;
  font-size: 30px;
}

@media (max-width: 1600px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    width: 48px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    width: 46px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    width: 44px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    width: 42px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    height: 48px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    height: 46px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    height: 44px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    height: 42px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    height: 40px;
  }
}

@media (max-width: 1600px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    font-size: 28px;
  }
}

@media (max-width: 1366px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .index-product .cont .product-swiper .more-cont .more .iconfont {
    font-size: 20px;
  }
}

.index-product .cont .product-swiper .swiper {
  padding: 20px;
  padding-bottom: 0;
}

.index-search {
  position: relative;
}

.index-search::after {
  content: '';
  display: block;
  height: 100%;
  background-color: #007f86;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 20%;
}

.index-search .container {
  position: relative;
  z-index: 1;
}

.index-search .container .cont {
  position: relative;
  padding-top: 90px;
  padding-bottom: 110px;
  padding-left: 95px;
}

@media (max-width: 1600px) {
  .index-search .container .cont {
    padding-top: 76px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont {
    padding-top: 62px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont {
    padding-top: 48px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont {
    padding-top: 34px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .cont {
    padding-bottom: 92px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont {
    padding-bottom: 74px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont {
    padding-bottom: 56px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont {
    padding-bottom: 38px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .cont {
    padding-left: 76px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont {
    padding-left: 57px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont {
    padding-left: 38px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont {
    padding-left: 19px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont {
    padding-left: 0px;
  }
}

.index-search .container .cont .bg {
  background-color: #000;
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
  left: 0;
  width: 120%;
}

@media (max-width: 767px) {
  .index-search .container .cont .bg {
    left: -15px;
  }
}

.index-search .container .cont .bg b {
  display: block;
  width: 100%;
  height: 150%;
  opacity: .5;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -50%;
  left: 0;
}

.index-search .container .cont .search {
  position: relative;
  z-index: 1;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 1024px) {
  .index-search .container .cont .search {
    display: block;
  }
}

.index-search .container .cont .search .search-title {
  flex-shrink: 0;
  line-height: 1;
  margin-right: 80px;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-title {
    margin-right: 64px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-title {
    margin-right: 48px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-title {
    margin-right: 32px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-title {
    margin-right: 16px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-title {
    margin-right: 0px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-title {
    margin-bottom: 10px;
  }
}

.index-search .container .cont .search .search-title h1 {
  font-weight: bold;
  font-size: 50px;
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-title h1 {
    font-size: 44.4px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-title h1 {
    font-size: 38.8px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-title h1 {
    font-size: 33.2px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-title h1 {
    font-size: 27.6px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-title h1 {
    font-size: 22px;
  }
}

.index-search .container .cont .search .search-title h2 {
  font-weight: bold;
  font-size: 64px;
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-title h2 {
    font-size: 57.2px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-title h2 {
    font-size: 50.4px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-title h2 {
    font-size: 43.6px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-title h2 {
    font-size: 36.8px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-title h2 {
    font-size: 30px;
  }
}

.index-search .container .cont .search .search-form {
  flex-grow: 1;
}

.index-search .container .cont .search .search-form .form {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form {
    display: block;
  }
}

.index-search .container .cont .search .search-form .form .form-radio-group {
  flex-shrink: 0;
  width: 76.3%;
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-radio-group {
    width: 100%;
  }
}

.index-search .container .cont .search .search-form .form .form-radio-group .form-title {
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    font-size: 20.8px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-top: 14px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-title {
    margin-top: 10px;
  }
}

.index-search .container .cont .search .search-form .form .form-radio-group .form-radio {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: wrap;
}

.index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  width: fit-content;
  margin-right: 40px;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-right: 36px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-right: 32px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-right: 28px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-right: 24px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-right: 20px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    margin-bottom: 10px;
  }
}

.index-search .container .cont .search .search-form .form .form-radio-group .form-radio label input {
  opacity: 0;
  margin-right: 15px;
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label input {
    margin-right: 10px;
  }
}

.index-search .container .cont .search .search-form .form .form-radio-group .form-radio label span::before {
  content: '';
  display: block;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label span::before {
    width: 16px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label span::before {
    height: 16px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label span::before {
    top: calc(50% - 8px);
  }
}

.index-search .container .cont .search .search-form .form .form-radio-group .form-radio label span::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  left: 4px;
  opacity: 0;
  transition: all .25s;
  width: 12px;
  height: 12px;
  top: calc(50% - 6px);
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label span::after {
    width: 8px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label span::after {
    height: 8px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label span::after {
    top: calc(50% - 4px);
  }
}

.index-search .container .cont .search .search-form .form .form-radio-group .form-radio label input:checked + span::after {
  opacity: 1;
}

.index-search .container .cont .search .search-form .form .form-btn {
  flex-shrink: 0;
  width: 22.36%;
  margin-top: 0;
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-btn {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-btn {
    margin-top: 10px;
  }
}

.index-search .container .cont .search .search-form .form .form-btn .search-btn {
  width: 100%;
  display: block;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all .3s;
  border: solid #fff;
  text-align: center;
  color: #fff;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-width: 3px;
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-top: 14px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-top: 12px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    border-width: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    border-width: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    border-width: 2.4px;
  }
}

@media (max-width: 991px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    border-width: 2.2px;
  }
}

@media (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-btn .search-btn {
    border-width: 2px;
  }
}

.index-search .container .cont .search .search-form .form .form-btn .search-btn:hover {
  background-color: #007f86;
  border: solid #007f86;
}

.index-about {
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  display: flex;
  padding-top: 130px;
  padding-bottom: 140px;
}

@media (max-width: 767px) {
  .index-about {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index-about {
    padding-top: 110px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-top: 90px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-top: 70px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about {
    padding-bottom: 112px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-bottom: 84px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-bottom: 56px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-bottom: 0px;
  }
}

.index-about::after {
  content: '';
  width: 100%;
  display: block;
  background-color: #f1f4f7;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 270px;
}

@media (max-width: 1600px) {
  .index-about::after {
    height: 232px;
  }
}

@media (max-width: 1366px) {
  .index-about::after {
    height: 194px;
  }
}

@media (max-width: 1024px) {
  .index-about::after {
    height: 156px;
  }
}

@media (max-width: 991px) {
  .index-about::after {
    height: 118px;
  }
}

@media (max-width: 767px) {
  .index-about::after {
    height: 80px;
  }
}

.index-about .left-text {
  flex-shrink: 0;
  width: 50%;
  padding-left: 176px;
  padding-right: 170px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .index-about .left-text {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text {
    padding-left: 143.8px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text {
    padding-left: 111.6px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text {
    padding-left: 79.4px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text {
    padding-left: 47.2px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text {
    padding-right: 139px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text {
    padding-right: 108px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text {
    padding-right: 77px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text {
    padding-right: 46px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text {
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text {
    margin-bottom: 20px;
  }
}

.index-about .left-text .title1 {
  color: #000;
  font-weight: bold;
  margin-bottom: 130px;
  font-size: 60px;
}

@media (max-width: 1600px) {
  .index-about .left-text .title1 {
    margin-bottom: 112px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .title1 {
    margin-bottom: 94px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .title1 {
    margin-bottom: 76px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .title1 {
    margin-bottom: 58px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .title1 {
    margin-bottom: 40px;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .title1 {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .title1 {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .title1 {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .title1 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .title1 {
    font-size: 30px;
  }
}

.index-about .left-text .title2 {
  color: #007f86;
  max-width: 430px;
  position: relative;
  font-style: italic;
  margin-bottom: 60px;
  font-size: 50px;
}

@media (max-width: 1600px) {
  .index-about .left-text .title2 {
    margin-bottom: 52px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .title2 {
    margin-bottom: 44px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .title2 {
    margin-bottom: 36px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .title2 {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .title2 {
    margin-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .title2 {
    font-size: 44.4px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .title2 {
    font-size: 38.8px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .title2 {
    font-size: 33.2px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .title2 {
    font-size: 27.6px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .title2 {
    font-size: 22px;
  }
}

.index-about .left-text .title2 span {
  position: absolute;
  top: 50%;
  font-style: normal;
  right: -14px;
  font-size: 120px;
}

@media (max-width: 767px) {
  .index-about .left-text .title2 span {
    right: 140px;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .title2 span {
    font-size: 104px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .title2 span {
    font-size: 88px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .title2 span {
    font-size: 72px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .title2 span {
    font-size: 56px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .title2 span {
    font-size: 40px;
  }
}

.index-about .left-text .text {
  text-align: justify;
  text-justify: inter-ideograph;
  /*IE*/
  line-height: 2;
  font-size: 18px;
}

@media (max-width: 767px) {
  .index-about .left-text .text {
    text-align: left;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .text {
    line-height: 1.9;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .text {
    line-height: 1.8;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .text {
    line-height: 1.7;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .text {
    line-height: 1.6;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .text {
    line-height: 1.5;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .text {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .text {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .text {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .text {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .text {
    font-size: 14px;
  }
}

.index-about .left-text .more {
  margin-top: 45px;
}

@media (max-width: 1600px) {
  .index-about .left-text .more {
    margin-top: 40px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .more {
    margin-top: 35px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .more {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .more {
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .more {
    margin-top: 20px;
  }
}

.index-about .left-text .more a {
  display: inline-block;
  color: #007f86;
  position: relative;
  transition: all .3s;
  border: solid #007f86;
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 70px;
  padding-right: 70px;
  border-width: 3px;
}

@media (max-width: 1600px) {
  .index-about .left-text .more a {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .more a {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .more a {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .more a {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .more a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .more a {
    padding-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .more a {
    padding-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .more a {
    padding-top: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .more a {
    padding-top: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .more a {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .more a {
    padding-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .more a {
    padding-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .more a {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .more a {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .more a {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .more a {
    padding-left: 62px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .more a {
    padding-left: 54px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .more a {
    padding-left: 46px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .more a {
    padding-left: 38px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .more a {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .more a {
    padding-right: 62px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .more a {
    padding-right: 54px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .more a {
    padding-right: 46px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .more a {
    padding-right: 38px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .more a {
    padding-right: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .left-text .more a {
    border-width: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index-about .left-text .more a {
    border-width: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index-about .left-text .more a {
    border-width: 2.4px;
  }
}

@media (max-width: 991px) {
  .index-about .left-text .more a {
    border-width: 2.2px;
  }
}

@media (max-width: 767px) {
  .index-about .left-text .more a {
    border-width: 2px;
  }
}

.index-about .left-text .more a:hover {
  background-color: #007f86;
  color: #fff;
}

.index-about .right-img {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  width: 50%;
}

@media (max-width: 767px) {
  .index-about .right-img {
    width: 100%;
  }
}

.index-about .right-img b {
  display: block;
  width: 100%;
  height: 120%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.index-about .right-img .img-text {
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
  padding-top: 45px;
  padding-bottom: 45px;
  padding-left: 170px;
  padding-right: 10px;
}

@media (max-width: 1600px) {
  .index-about .right-img .img-text {
    padding-top: 40px;
  }
}

@media (max-width: 1366px) {
  .index-about .right-img .img-text {
    padding-top: 35px;
  }
}

@media (max-width: 1024px) {
  .index-about .right-img .img-text {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .index-about .right-img .img-text {
    padding-top: 25px;
  }
}

@media (max-width: 767px) {
  .index-about .right-img .img-text {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .right-img .img-text {
    padding-bottom: 40px;
  }
}

@media (max-width: 1366px) {
  .index-about .right-img .img-text {
    padding-bottom: 35px;
  }
}

@media (max-width: 1024px) {
  .index-about .right-img .img-text {
    padding-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .index-about .right-img .img-text {
    padding-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .index-about .right-img .img-text {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .right-img .img-text {
    padding-left: 138px;
  }
}

@media (max-width: 1366px) {
  .index-about .right-img .img-text {
    padding-left: 106px;
  }
}

@media (max-width: 1024px) {
  .index-about .right-img .img-text {
    padding-left: 74px;
  }
}

@media (max-width: 991px) {
  .index-about .right-img .img-text {
    padding-left: 42px;
  }
}

@media (max-width: 767px) {
  .index-about .right-img .img-text {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .right-img .img-text {
    padding-right: 10px;
  }
}

@media (max-width: 1366px) {
  .index-about .right-img .img-text {
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .index-about .right-img .img-text {
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  .index-about .right-img .img-text {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .index-about .right-img .img-text {
    padding-right: 10px;
  }
}

.index-about .right-img .img-text .item {
  color: #fff;
}

@media (max-width: 767px) {
  .index-about .right-img .img-text .item {
    text-align: center;
  }
}

.index-about .right-img .img-text .item:not(:last-child) {
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .index-about .right-img .img-text .item:not(:last-child) {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-about .right-img .img-text .item:not(:last-child) {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-about .right-img .img-text .item:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-about .right-img .img-text .item:not(:last-child) {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-about .right-img .img-text .item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.index-about .right-img .img-text .item h1 {
  font-size: 80px;
}

@media (max-width: 1600px) {
  .index-about .right-img .img-text .item h1 {
    font-size: 72px;
  }
}

@media (max-width: 1366px) {
  .index-about .right-img .img-text .item h1 {
    font-size: 64px;
  }
}

@media (max-width: 1024px) {
  .index-about .right-img .img-text .item h1 {
    font-size: 56px;
  }
}

@media (max-width: 991px) {
  .index-about .right-img .img-text .item h1 {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .index-about .right-img .img-text .item h1 {
    font-size: 40px;
  }
}

.index-about .right-img .img-text .item p {
  opacity: .8;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-about .right-img .img-text .item p {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index-about .right-img .img-text .item p {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index-about .right-img .img-text .item p {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index-about .right-img .img-text .item p {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index-about .right-img .img-text .item p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1680px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    width: 25%;
  }
}

@media screen and (max-width: 1680px) and (max-width: 1366px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    width: 25%;
  }
}

@media screen and (max-width: 1680px) and (max-width: 1024px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    width: 33.33%;
  }
}

@media screen and (max-width: 1680px) and (max-width: 767px) {
  .index-search .container .cont .search .search-form .form .form-radio-group .form-radio label {
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .index-banner {
    padding-right: 70px;
  }
  .index-banner .banner-cont .swiper-pagination {
    right: -40px;
  }
}

@media screen and (max-width: 767px) {
  .index-banner {
    padding-right: 0px;
  }
  .index-banner .banner-cont .swiper-pagination {
    right: 10px;
  }
}
